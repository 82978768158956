$env: qa;
@import '@/styles/_component-base.styles.scss';

.shoppableTile {
    @include only-breakpoint(lg) {
        .shoppableTileFinancing {
            div:first-child {
                opacity: 0;
            }

            div:last-child {
                padding-left: 0;
                margin-left: 0;
                border-left: 0;
            }
        }
    }
}

.shoppableTileContainer a {
    height: 330px;

    @include breakpoint(lg) {
        height: 215px;

        &:hover, &:focus-within {
            .shoppableTileName {
                opacity: 0;
            }

            .shoppableTileFinancing {
                display: flex;
            }
        }
    }

    div[class^="lazyload"] {
        width: 100%;
        height: 100%;
    }
}

.shoppableTileContent {
    height: 100%;
    padding: 12px 12px;
    background-image: none;
}

.shoppableTileTag {
    flex-grow: 1;
}

.shoppableTileName {
    // Shoppable tile from bit uses this selector...
    :global(.name) {
        font-size: set-fontSize(24);

        @include breakpoint(sm) {
            font-size: set-fontSize(24);
        }
    }

    :global(.description) {
        letter-spacing: 1px;
    }
}

.shoppableTileFinancing {
    font-size: set-fontSize(14);

    @include breakpoint(lg) {
        display: none;
    }

    div:last-child {
        font-size: set-fontSize(20);

        span {
            font-size: set-fontSize(14);
        }
    }
}

//-------------------------------------------
// Custom css for override affirm styles
// ------------------------------------------
.affirm-shoppable-tile a {
    &:hover {
        text-decoration: underline;
    }

    color: color(contrast, white);

    .affirm-ala-element .affirm-ala-price {
        margin-left: spacer(3xs);
        font-size: set-fontSize(18);

        @include breakpoint(md) {
            margin-left: spacer(2xs);
            font-size: set-fontSize(20);
        }
    }
}

.viewMoreLink {
    font-size: set-fontSize(18);
}
