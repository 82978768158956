$env: qa;
@import '@/styles/_component-base.styles.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    max-height: 768px;
}

.backgroundVideo{
    video{
        width: 100%;
        min-height: 280px;
        object-fit: cover;
    }
}

.playButton{
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
    background-color: rgba(color(text,base), 0.2);
}


.text{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 spacer(lg);
    font-size: set-fontSize(24);
    font-family: $fontFamily-serif;
    color: color(contrast, white);

    @include breakpoint(md) {
        font-size: set-fontSize(28);
    }

    @include breakpoint(lg) {
        font-size: set-fontSize(32);
    }
}

.playVideoCTA{
    display: flex;
    align-items: center;
    gap: 6px;
    letter-spacing: 0.5px;
    font-weight: normal;
    margin-top: spacer(sm);
    padding: spacer(sm) spacer(base);
    font-size: set-fontSize(16);
    color: color(text, headingDark);
    font-family: $fontFamily-sans-serif;
    background-color: color(contrast, white);

    &:hover {
        background-color: color(contrast, 4);
    }

    @include breakpoint(md) {
        gap: 10px;
        margin-top: spacer(lg);
        font-size: set-fontSize(18);
        padding: spacer(base) spacer(lg);
    }
}

.playVideoIcon {
    width: 12px;
    height: 14px;
    display: inline-flex;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 13px solid color(text, headingDark);
}

.video {
    width: calc(100vw - 2rem);
    display: block;
    height: unset;
    overflow: visible;

    @include breakpoint(lg){
        width: 900px;
    }
    @include breakpoint(xl){
        width: 1080px;
    }
    @media (max-height: 400px) and (min-width: 576px) and (orientation: landscape) {
        width: calc(100vw - 8rem);
    }
}

.videoModal {
    overflow: visible;
    background: none;
    max-width: none;
    width: auto;
}